import './Hero.css';
import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTrail, a } from '@react-spring/web';
import japan from '../../assets/hero/japan.jpg';

const getLineHeightInPixels = (vw) => {
    return window.innerWidth * (vw / 100);
};

export const Trail = ({ open, children }) => {
    const items = React.Children.toArray(children);
    const trail = useTrail(items.length, {
        config: { mass: 3, tension: 150, friction: 18 },
        opacity: open ? 0.75 : 0,
        x: open ? 0 : 20,
        height: open ? getLineHeightInPixels(9) : 0,
        from: { opacity: 0, x: 20, height: 0 },
    });

    return (
        <>
            {
                // if index is 2, subtitle text
                trail.map(({ height, ...style }, index) => (
                    <a.div key={index} style={style}>
                        <a.div className="trails-text" style={{ height: height }}>
                            {items[index]}
                        </a.div>
                    </a.div>
                ))
            }
        </>
    );
};

export const TitleText = ({ inView, isBelowSmall, isBelowXSmall }) => {
    // for future changes, can add animation change when scrolling back into view
    useEffect(() => {}, [inView]);

    const [open, setOpen] = useState(true);
    const [posTop, setPosTop] = useState('25%');
    useEffect(() => {
        isBelowSmall ? setPosTop('33%') : setPosTop('25%');
        if (isBelowXSmall) setPosTop('45%');
    }, [isBelowSmall, isBelowXSmall]);

    const divStyle = {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        top: posTop,
        left: '5%',
        textAlign: 'left',
        zIndex: 1,
        color: 'white',
    };

    // <div style={divStyle} onLoad={() => setOpen(state => !state)}>
    return (
        <div style={divStyle} onLoad={() => setOpen((state) => !state)}>
            <Trail open={open}>
                <span className="text1">丘利也</span>
                <h1 className="text2">NICHOLAS HIOE</h1>
                <h2 className="text3">Computer Science Student at the University of Waterloo.</h2>
            </Trail>
        </div>
    );
};

export const Hero = () => {
    const theme = useTheme();
    const isBelowSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const isBelowXSmall = useMediaQuery(theme.breakpoints.down('xs'));
    return (
        <div className="hero-container">
            <TitleText isBelowSmall={isBelowSmall} isBelowXSmall={isBelowXSmall} />
            <div className="background-mask"></div>
            <img className="bg-item" src={japan} alt="background" />
        </div>
    );
};

export default Hero;
