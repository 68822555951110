import { createTheme } from '@mui/material/styles';
import '@fontsource-variable/noto-sans-tc';
import '@fontsource-variable/sora';

export const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 992,
        lg: 1200,
        xl: 1536,
      },
    },

    palette: {
      primary: {
        main: '#1b1a1a',
      },
      secondary: {
        main: '#111111',
      }
    },
    typography: {
      fontFamily: 'Inconsolata, monospace',
      h2: {
        fontFamily: 'Sora Variable, sans-serif',
        fontSize: '1.2vw',
        fontWeight: 500,
        letterSpacing: 10,
        '@media (max-width:991px)': {
          fontSize: 18, 
        },
        '@media (max-width:767px)': {
          fontSize: 14, 
        }
      },
        h3: {
          fontFamily: 'Sora Variable, sans-serif',
          fontSize: '3.2vw',
          fontWeight: 350,
          letterSpacing: '0.8vw',
          '@media (max-width:991px)': {
            fontSize: 30,
            letterSpacing: 8,
          },
          '@media (max-width:767px)': {
            fontSize: '5vw',
            letterSpacing: '0.8vw',
            fontWeight: 500,
          }
        },
        h4: {
          fontFamily: 'Sora Variable, sans-serif', 
          fontSize: '2.6vw',
          fontWeight: 250,
          letterSpacing: '0.5vw',
          '@media (max-width:991px)': {
            fontSize: 25,
            letterSpacing: 6,
            fontWeight: 250,
          },
          '@media (max-width:767px)': {
            fontSize: '2.8vw',
            letterSpacing: '0.8vw',
            fontWeight: 400,
          },
        },
        h5: {
          fontFamily: 'Sora Variable, sans-serif', 
          fontSize: '2.1vw',
          marginTop: '1vw',
          fontWeight: 250,
          letterSpacing: '0.8vw',
          '@media (max-width:991px)': {
            fontSize: 20,
            marginTop: 10,
            letterSpacing: 5,
            fontWeight: 250,
          },
          '@media (max-width:767px)': {
            marginTop: '2vw',
            fontSize: '2.5vw',
            fontWeight: 400,
            letterSpacing: '0.8vw',
          }
        },

        // Projects
        h6: {
          fontFamily: 'Sora Variable, sans-serif',
          fontSize: '2.8vw',
          letterSpacing: '0.05vw',
          fontWeight: 200,
          '@media (max-width:1199px)': {
            fontSize: 35,
            letterSpacing: 4,
          },
          '@media (max-width:991px)': {
            fontSize: 28,
            letterSpacing: 3,
          },
          '@media (max-width:767px)': {
            fontSize: '6vw',
            letterSpacing: '0.1vw',
          }
        },

        p: {
          fontFamily: 'Noto Sans TC Variable, sans-serif',
          fontSize: '1.1vw',
          fontWeight: 400,
          '@media (max-width:991px)': {
            fontSize: 13,
          },
          '@media (max-width:767px)': {
            fontSize: '1.7vw', 
          }      
        },

        button: {
          fontFamily: 'Sora Variable, sans-serif',
          fontSize: '1.05vw',
          fontWeight: 200,
          '@media (max-width: 1535px)': {
            fontSize: 18,
          },
        },
    },

    Tooltip: {
      '@media (max-width: 767px)': {
        enterTouch: 200,
      },
    }
  });

export default theme;