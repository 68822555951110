import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import { useInView } from "react-intersection-observer";
import theme from './Theme.js';

import NavBar from './components/Navbar/Navbar.js';
import Hero from './components/Hero/Hero.js';
import Experience from './components/Experience/Experience.js';
import Projects from './components/Projects/Projects.js';
import Contact from './components/Contact/Contact.js';


const App = () => {

  const [ref1, inView1] = useInView({
    threshold: 0.5
  });

  // Projects
  const [ref2, inView2] = useInView({
    threshold: 0,
  });


  const [ref3, inView3] = useInView({
    threshold: 0.1,
  });

  return (
    // navbar
    <ThemeProvider theme={theme}>
      <div className="page-content">
        <NavBar inViews={ [inView1, inView2, inView3] }/>
        <section id="#" className="content" ref={ ref1 }>
          <Hero inView1={inView1}/>
        </section>
        <section className="content" id="experience" ref={ ref2 }>
          <Experience inView={ inView2 }/>
        </section>
        <section id="projects" className="content" ref={ ref3 }>
          <Projects inView={ inView3 } beforeView={ inView2 }/>
        </section>
        <section id="contact" className="content">
          <Contact/>
        </section>
      </div>
    </ThemeProvider>
  );
}

export default App;