import './Experience.css';
import React from 'react';
import { useEffect, useState, useRef } from 'react';
import Button from '@mui/material/Button';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';


import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Tooltip } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import { useInView, useSpring, animated, a } from '@react-spring/web';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import { register } from 'swiper/element/bundle';

import experienceData from './experienceData.js';
register();

const FlipCard = ({ type, images, title, job, technologies, date, icon, location, text, flipped }) => {
    const [ref, springs] = useInView(
        () => ({
          from: {
            opacity: 0,
            x: 100,
          },
          to: {
            opacity: 1,
            x: 0,
          },
        }),
        {
          rootMargin: '-10% 0%',
        }
    )

    const { transform, opacity } = useSpring({
        opacity: flipped ? 1 : 0,
        transform: `perspective(1000px) rotateX(${flipped ? 180 : 0}deg)`,
        config: { mass: 4, tension: 500, friction: 90 },
    })

    return(
        <animated.div className="slide-base" style={springs} ref={ref}>
            <div className="slide-image-skeleton"></div>
            <a.div
            className="slide-base-content"
            style={{
                opacity: opacity.to(o => 1 - o),
                transform,
            }}
            >
                <Grid container justifyContent="center" padding={1}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                        <Typography variant="h3" color="white" textTransform="uppercase">
                            {title}
                        </Typography>
                        <img
                            src={icon}
                            alt={title + " logo"}
                            className="carousel-icon"
                        />
                    </Box>
                    <Grid xs={12}>
                        <Typography variant="h4" color="white" textAlign="center" textTransform="uppercase">
                            {date}
                        </Typography>
                        <Typography variant="h5" color="white" textAlign="center" textTransform="uppercase">
                            {location}
                        </Typography> 
                    </Grid>
                </Grid>
                <div className="slide-image-overlay"></div>
                <img src={images[0]} alt={title + " background-front"} className="slide-image"/>  
            </a.div>
            <a.div
            className="slide-base-content"
            style={{
                opacity,
                transform: transform.to(t => `${t} rotateX(180deg)`),
            }}>
                <Grid container alignItems="center" justifyContent="flex-end">
                    {
                        type === "job" ? (
                        <>
                            <Grid xs={12}>
                                <Typography variant="h4" color="white" textAlign="center" fontWeight="500" textTransform="uppercase">
                                    {job}
                                </Typography>
                            </Grid>
                            <Grid xs={12} className="list-container">
                                <ul className="list">
                                    {
                                        text.map((item, index) => (
                                            <li key={index} className="list-item">
                                                <span className="list-item-content">
                                                    <Typography variant="p" color="white" textAlign="left">
                                                    {item}
                                                    </Typography>
                                                </span>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </Grid>
                            <Grid xs={12} className="job-technologies">
                                    {
                                    technologies.map((tech, index) => (
                                        <Tooltip key={index} disableInteractive title={tech.name}>
                                            <img src={tech.src} alt={tech.name} />
                                        </Tooltip>
                                    ))
                                    }
                            </Grid>
                        </>
                        ) : (
                        <Grid xs={12} className="list-container">
                            <ul className="list">
                                {
                                    text.map((item, index) => (
                                        <li key={index} className="list-item">
                                            <span className="list-item-content">
                                                <Typography variant="p" color="white" textAlign="left">
                                                {item}
                                                </Typography>
                                            </span>
                                        </li>
                                    ))
                                }
                            </ul>
                        </Grid>
                        )
                    }
                </Grid>
                <div className="slide-image-overlay"></div>
                <img src={images[1]} alt={title + " background-back"}className="slide-image"/>  
            </a.div>
      </animated.div>
    )
}

// add trail animation to this!

export const Experience = ({ inView }) => {
    const theme = useTheme();
    const isBelowXSmall = useMediaQuery(theme.breakpoints.down('xs'));
    const isBelowSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const isBelowMed = useMediaQuery(theme.breakpoints.down('md'));
    const isBelowLarge = useMediaQuery(theme.breakpoints.down('lg'));

    const swiperRef = useRef();

    const [numSlides, setNumSlides] = useState(3.2);

    const [currentBackground, setCurrentBackground] = useState(experienceData[0].images[0]);

    useEffect(() => {
        isBelowLarge ? setNumSlides(1.8) : setNumSlides(2.2);
        if (isBelowMed) setNumSlides(1.6);
        if (isBelowSmall) setNumSlides(1.4);
        if (isBelowXSmall) setNumSlides(1);
    }, [isBelowLarge, isBelowMed, isBelowSmall, isBelowXSmall])

    useEffect(() => {
      const params = {
        slidesPerView: numSlides,
        centeredSlides: true,
        spaceBetween: 40,
        effect: 'coverflow',
        freeMode: {
            enabled: true,
            momentum: true,
            momentumRatio: 0.5,
            momentumVelocityRatio: 0.5,
            sticky: true,
        },
        injectStyles: [
            `
              .swiper-button-next,
              .swiper-button-prev {
                content: "";
                display: none;
              }
              .swiper-button-next::after,
              .swiper-button-prev::after {
                content: "";
              }
            }
          `,
          ],
      };

    Object.assign(swiperRef.current, params);
    swiperRef.current.initialize();

    swiperRef.current.swiper.on('transitionEnd', () => {
        setFlipped(resetFlipStatus);
    });
    }, [numSlides]);

    const handlePrev = () => {
        swiperRef.current.swiper.slidePrev();
    }
    const handleNext = () => {
        swiperRef.current.swiper.slideNext();
    }

    const [flipped, setFlipped] = useState(Array.from( { length: experienceData.length }, () => false));
    
    // handle flipping, as well as clicking slides to navigate
    const changeFlipStatus = (index) => {
        if(swiperRef.current.swiper.activeIndex === index) {
            setFlipped(prev => {
                setCurrentFlipped(!currentFlipped);
                const newFlipped = [...prev];
                newFlipped[index] = !newFlipped[index];
                return newFlipped;
            });
        } else {
            if (index > swiperRef.current.swiper.activeIndex) {
                handleNext();
            } else {
                handlePrev();
            }
        }
    };

    // on navigation, flip all
    const resetFlipStatus = () => {
        setCurrentFlipped(false);
        return Array.from(Array.from( { length: experienceData.length }, () => false));
    }

    const [ref, springs] = useInView(
        () => ({
          from: {
            opacity: 0,
          },
          to: {
            opacity: 1,
          },
        }),
        {
          rootMargin: '-10% 10%',
        }
    )

    const [currentFlipped, setCurrentFlipped] = useState(false);

    useEffect(() => {
        const swiperInstance = swiperRef.current.swiper;
        const handleSlideChange = () => {
            setCurrentFlipped(false);
            updateBackground();
        }
        const updateBackground = () => {
            const activeIndex = swiperInstance.activeIndex;
            setCurrentBackground(experienceData[activeIndex].images[currentFlipped ? 1 : 0]);
        };
        swiperInstance.on('slideChange', handleSlideChange);

        updateBackground();
    }, [currentFlipped])

    return(
        <div className="experience-container">
            <div className="swiper-container-background" style={{ backgroundImage: `url(${currentBackground})`}}></div>
            <swiper-container ref={swiperRef} init="false">
                {
                    experienceData.map((item, index) => {
                        return(
                            <swiper-slide key={index} onClick={() => changeFlipStatus(index)}>
                                <FlipCard {...item} flipped={flipped[index]} />
                            </swiper-slide>
                        )
                    })
                }
            </swiper-container>
            <div className="swiper-navigation-container">
                <animated.div ref={ref} style={springs}>
                    <Button className="nav-button" aria-label="previous slide button"
                    sx={{
                        backgroundColor: 'rgb(230, 230, 230)', 
                        margin: '2px',
                        minWidth: '32px',
                        '&:hover': {
                            backgroundColor: 'rgb(241, 241, 220)',
                        }
                    }} onClick={handlePrev}>
                        <NavigateBeforeIcon/>
                    </Button>
                </animated.div>
                <animated.div ref={ref} style={springs}>
                    <Button className="nav-button" aria-label="next slide button"
                    sx={{ 
                        backgroundColor: 'rgb(230, 230, 230)',
                        margin: '2px',
                        minWidth: '32px',
                        '&:hover': {
                            backgroundColor: 'rgb(241, 241, 220)',
                        }
                    }} onClick={handleNext}>
                        <NavigateNextIcon />
                    </Button>
                </animated.div>
            </div>
        </div>
    )
};

export default Experience;