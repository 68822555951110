import React from 'react';
import { useEffect } from 'react';
import Link from '@mui/material/Link';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles'
import EmailIcon from '@mui/icons-material/Email';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import logo from '../../assets/hero/home-logo.png';
import './Navbar.css';

const pages = ['experience', 'projects', 'resume', 'contact'];
const resumePath = 'NicholasHioeResume.pdf'; // relative to root, since served in public

export const NavBar = () => {
  const theme = useTheme();
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'));

  // if burger menu is still open when resized, close it
  useEffect(() => {
    handleCloseNavMenu();
  }, [isBelowMD])

  // TEST HERE

  return (
    <AppBar position="fixed" color="secondary">
      <Container maxWidth="xl">
      <Toolbar disableGutters sx={{ justifyContent: 'space-between', alignContent: 'center', display: 'flex',}}>
          <Grid xs={8} sx={{ display: 'flex' }}>
            <Button
              component="a"
              href="#"
              sx={{
                '&:hover': {
                  color: 'rgb(241, 241, 180);',
                },
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
                <img src={logo} alt="NHIOE" className="nav-logo" />
              </Button>
          </Grid>

          <Grid xs={4} sx={{ display: 'flex' }}>
            { 
              isBelowMD ? (
                <>
                  <Link
                  href={"mailto:nicholas.hioe@gmail.com"} 
                  aria-label="Contact me via email!"
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="nav-social">
                    <Button aria-label="Contact me via email!" sx={{ color: "white" }}>
                      <EmailIcon alt="email icon" sx={{ fontSize: 'max(24px, 1vw)' }}/>
                    </Button>
                  </Link>
                  <Link 
                  href={"https://github.com/nhioe"}
                  aria-label="Find me on Github!"
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="nav-social">
                    <Button aria-label="Find me on Github!" sx={{ color: "white" }}>
                      <GitHubIcon alt="github logo" sx={{ fontSize: 'max(24px, 1vw)' }}/>
                    </Button>
                  </Link>
                  <Link 
                  href={"https://www.linkedin.com/in/nicholas-hioe"}
                  aria-label="Connect with me on LinkedIn!" 
                  target="_blank"
                  rel="noopener noreferrer" 
                  className="nav-social">
                    <Button aria-label="Contact me on LinkedIn!" sx={{ color: "white" }}>
                      <LinkedInIcon alt="linkedin logo" sx={{ fontSize: 'max(24px, 1vw)' }}/>
                    </Button>
                  </Link>
                  <IconButton
                    id="burger-icon"
                    size="large"
                    aria-label="menu-appbar"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                      display: { xs: 'block', md: 'none' },
                    }}
                  >
                    <nav className="limited-nav-container">
                    {
                    
                      pages.map((page, index) => (
                          page === "resume" ? (
                            <a key={index} href={resumePath}target="_blank" rel="noopener noreferrer" className="burgerNavItem" >
                                <MenuItem className="burgerButton"
                                onClick={handleCloseNavMenu}
                                variant="contained"
                                disableRipple
                                key={page}
                                sx={{ color: 'black', display: 'flex', fontFamily: 'Sora Variable, sans-serif',
                                fontSize: 16, textTransform: 'uppercase', py: 1.5, px: 3}}>
                                    {page}
                                </MenuItem>
                            </a>
                          ) : (
                            <a key={index} href={'#' + page } className="burgerNavItem" >
                              <MenuItem className="burgerButton"
                              onClick={handleCloseNavMenu}
                              variant="contained"
                              disableRipple
                              key={page}
                              sx={{ color: 'black', display: 'flex', fontFamily: 'Sora Variable, sans-serif',
                              fontSize: 16, textTransform: 'uppercase', py: 1.5, px: 3}}>
                                  {page}
                              </MenuItem>
                          </a>
                          )
                      ))
                    }
                    </nav>
                  </Menu>
                </>
              ) : 
                (
                <>
                  <nav className="extended-nav-container">
                  {
                    pages.map((page, index) => (
                      page === "resume" ? (
                          <Button
                          aria-label={"navigate to" + page}
                          className="navButton"
                          key={index}
                          href={resumePath}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={
                            handleCloseNavMenu
                          }
                          sx={{ color: 'white', display: 'flex', mx: 3 }}
                          >
                          {page}
                        </Button>
                      ) : (
                        <Button
                        aria-label={"navigate to" + page}
                        className="navButton"
                        key={index}
                        href={'#' + page }
                        onClick={
                          handleCloseNavMenu
                        }
                        sx={{ color: 'white', display: 'flex', mx: 3 }}
                        >
                          {page}
                        </Button>
                      )
                    ))
                  }
                  </nav>
                  <Link
                  href={"mailto:nicholas.hioe@gmail.com"} 
                  aria-label="Contact me via email!"
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="nav-social">
                    <Button aria-label="Contact me via email!" sx={{ color: "white" }}>
                      <EmailIcon alt="email icon" sx={{ fontSize: 'max(24px, 1vw)' }}/>
                    </Button>
                  </Link>
                  <Link 
                  href={"https://github.com/nhioe"}
                  aria-label="Find me on Github!"
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="nav-social">
                    <Button aria-label="Find me on Github!" sx={{ color: "white" }}>
                      <GitHubIcon alt="github logo" sx={{ fontSize: 'max(24px, 1vw)' }}/>
                    </Button>
                  </Link>
                  <Link 
                  href={"https://www.linkedin.com/in/nicholas-hioe"}
                  aria-label="Connect with me on LinkedIn!" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="nav-social">
                    <Button aria-label="Connect with me on LinkedIn!" sx={{ color: "white" }}>
                      <LinkedInIcon aria-label="Connect with me on LinkedIn!" alt="linkedin logo" sx={{ fontSize: 'max(24px, 1vw)' }}/>
                    </Button>
                  </Link>
                </>
              )
            }
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavBar;