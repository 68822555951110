// Assets:
// pref image size: 800 x 750
import merecipes from '../../assets/projects/merecipes.png';
import stockfinder from '../../assets/projects/stockfinder.png';
import pong from '../../assets/projects/pong.png';
import champsarena from '../../assets/projects/champsarena.png';
import raiinet from '../../assets/projects/raiinet.png';
import visabooker from '../../assets/projects/visabooker.png';
import rerecord from '../../assets/projects/rerecord.png';
import twittercow from '../../assets/projects/twittercow.png';
import silentvoice from '../../assets/projects/silentvoice.png';
import youtubespotify from '../../assets/projects/youtubespotify.png';

// https://techicons.dev
import mysql from '../../assets/technologies/MySQL.svg';
import bootstrap from '../../assets/technologies/Bootstrap.svg';
import cPlusPlus from '../../assets/technologies/C++.svg';
import css from '../../assets/technologies/CSS3.svg';
import electron from '../../assets/technologies/electron-icon.svg';
import expressjs from '../../assets/technologies/Express.svg';
import html from '../../assets/technologies/HTML5.svg';
import java from '../../assets/technologies/Java.svg';
import javascript from '../../assets/technologies/JavaScript.svg';
import materialui from '../../assets/technologies/MaterialUI.svg';
import reactjs from '../../assets/technologies/React.svg';
import nodejs from '../../assets/technologies/Node.js.svg';
import python from '../../assets/technologies/Python.svg';
import flask from '../../assets/technologies/Flask.svg';
import opencv from '../../assets/technologies/OpenCV.svg';

export const projectData = [
    { 
        src: youtubespotify, 
        text: 'YouTube to Spotify',
        link: 'https://github.com/nhioe/youtube-to-spotify-chrome-extension',
        description: 'Chrome extension to add songs you find on YouTube to your Spotify playlists!',
        technologies: [
        {src: javascript, name: 'javascript'},
        {src: reactjs, name: 'react'},
        {src: materialui, name: 'material-ui'},
        ]
    },
    { 
        src: silentvoice, 
        text: 'Silent Voice',
        link: 'https://github.com/nhioe/SilentVoice',
        description: 'Real-time lip-reading transcription app.',
        technologies: [
        {src: javascript, name: 'javascript'},
        {src: reactjs, name: 'react'},
        {src: materialui, name: 'material-ui'},
        {src: python, name: 'python'},
        {src: flask, name: 'flask'},
        ]
    },
    {
        src: rerecord,
        text: 'Re-Record',
        link: 'https://github.com/nhioe/Re-Record',
        description: 'Computer vision app to practice recorder playing technique (UofTHacks 11).',
        technologies: [
        {src: css, name: 'css'},
        {src: javascript, name: 'javascript'},
        {src: python, name: 'python'},
        {src: reactjs, name: 'react'},
        {src: flask, name: 'flask'},
        {src: opencv, name: 'opencv'}
        ]
    },
    {
        src: visabooker,
        text: 'Visa Appt. Booker',
        link: '',
        description: 'Automated appointment booking electron app.',
        technologies: [
        {src: html, name: 'html'}, 
        {src: css, name: 'css'},
        {src: javascript, name: 'javascript'},
        {src: electron, name: 'electron'},
        {src: nodejs, name: 'nodejs'}
        ]
    },
    { 
        src: merecipes, 
        text: 'MeRecipes',
        link: 'https://github.com/nhioe/MeRecipes',
        description: 'Recipe and grocery list management app.',
        technologies: [
        {src: html, name: 'html'}, 
        {src: css, name: 'css'},
        {src: javascript, name: 'javascript'},
        {src: expressjs, name: 'express'},
        {src: bootstrap, name: 'bootstrap'},
        {src: mysql, name: 'mysql'}, 
        ]
    },
    { 
        src: stockfinder, 
        text: 'Stock Finder',
        link: 'https://github.com/nhioe/Stock-Finder',
        description: 'Stock data and news interface for Polygon.io API.',
        technologies: [
        {src: css, name: 'css'},
        {src: javascript, name: 'javascript'},
        {src: reactjs, name: 'react'},
        {src: materialui, name: 'material-ui'},
        ] 
    },
    {
        src: twittercow,
        text: 'Twitter Cow',
        link: 'https://github.com/kevin-klau/Twitter-Cow',
        description: 'Twitter web scraper to find trending vacation destinations from keywords (UofTHacks X).',
        technologies: [
        {src: html, name: 'html'},
        {src: css, name: 'css'},
        {src: javascript, name: 'javascript'},
        {src: python, name: 'python'},
        {src: flask, name: 'flask'},
        {src: bootstrap, name: 'bootstrap'},
        ]
    },
    {
        src: raiinet,
        text: 'Raiinet (Stratego)',
        link: 'https://github.com/roychon/RAIINet',
        description: '2-player Stratego-inspired board game created for CS 246 final project.',
        technologies: [
        {src: cPlusPlus, name: 'c++'}, 
        ] 
    },
    {
        src: champsarena,
        text: 'Champions Arena',
        link: 'https://github.com/andrearcaina/Champions-Arena',
        description: 'Local multiplayer top-down shooting survival game, for 2-4 players.',
        technologies: [
        {src: java, name: 'java' }
        ]
    },
    { 
        src: pong, 
        text: 'Pong',
        link: 'https://github.com/nhioe/Pong-Remake',
        description: 'Recreation of Atari\'s Pong, for 2-players.',
        technologies: [
        {src: java, name: 'java' }
        ]
    },
];

export default projectData;
